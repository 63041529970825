.quantity_box_wrapper {
    display: flex;
    justify-content: center; /* Centers the box horizontally */
    align-items: center; /* Centers the box vertically */
  }
  
  .quantity_box {
    background-color: grey;
    padding: 10px;
    border-radius: 5px;
    min-width: 50px; /* Minimum width for the box */
    max-width: 100px; /* Maximum width for the box */
    display: flex;
    justify-content: center; /* Centers the text horizontally */
    align-items: center; /* Centers the text vertically */
  }
  
  .fullWidthActions {
    display: flex; /* Use flex layout for full width */
    flex-direction: row; /* Ensures items align horizontally */
    justify-content: space-between; /* Distribute space between elements */
    align-items: center; /* Aligns items in the middle */
    width: 100%; /* Ensures full width */
  }
  