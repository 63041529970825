._login_logo {
    width: 250px;
}


/* Mobile styles */
@media (max-width: 768px) {
    ._login_logo {
        width: 100px;
    }
}